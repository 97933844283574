@font-face {
  font-family: 'sakae';
  src:
    url('../fonts/sakae.ttf?mndvj3') format('truetype'),
    url('../fonts/sakae.woff?mndvj3') format('woff'),
    url('../fonts/sakae.svg?mndvj3#sakae') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'sakae' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow:before {
  content: "\e900";
}
.icon-mail:before {
  content: "\e903";
}
.icon-tel:before {
  content: "\e904";
}
