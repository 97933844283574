/// @example HTML
///   <ol class="breadcrumb breadcrumb--arrow">
///     <li class="breadcrumb__item"><a href="#">トップページ</a></li>
///     <li class="breadcrumb__item"><a href="#">会社概要</a></li>
///     <li class="breadcrumb__item">代表挨拶</li>
///   </ol>

.breadcrumb {
  @include _unstyled(ol);
  letter-spacing: -.4em;

  &__item {
    position: relative;
    display: inline-block;
    letter-spacing: normal;
  }
}

$plain-breadcrumb-arrow-space-em: 1em !default;
$plain-breadcrumb-arrow-color: #999 !default;

.breadcrumb--arrow {
  .breadcrumb__item {
    &:not(:last-child) {
      padding-right: $plain-breadcrumb-arrow-space-em * 2.35;

      &::after {
        content: '';
        @include _absolute(calc(50% - .19em) $plain-breadcrumb-arrow-space-em + .1em);
        @include _arrow(right, .4em, 1px, $plain-breadcrumb-arrow-color);
      }
    }
  }
}
