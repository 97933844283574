/// Vertical Rhythm のための基本設定
/// _vr()関数, _vr-line-height()関数, _vr-font()関数で使用
$dots-base-line-height-px: 28px;

// 色設定
$primary-color: #435E67;
$secondary-color: #A1AEB3;
$font-color-primary: #2E2E2E;
$background-color-primary: #fff;
$background-color-secondary: #F7F7F3;
$border-color-primary: #969696;


// フォント設定

$font-stack-yu-mincho: (
  "Times New Roman",
  YuMincho,
  "Yu Mincho",
  "游明朝体",
  "Hiragino Mincho ProN",
  "HG明朝B",
  serif
) !default;

// 余白設定
$section-margin-small: 56px;
$section-margin-medium: 84px;
$section-margin-large: 126px;

// フレームワーク設定
$plain-burger-color: $primary-color;
//$plain-breadcrumb-arrow-color: $primary-color;

$plain-embed-type: iframe;
//$plain-embed-breakpoint: xl;
