@charset "utf-8";

/// `position:absolute` と位置の指定を1行で設定する
///
/// @param {list} $box-edge-values [null null null null]
///   要素の位置。
///   top, right, bottom, left の順で値がセットされる。
///   値をスキップする場合は `null` を使う。
///
/// @example scss
///   .element {
///     @include _absolute(10px null 20px 30px);
///   }
///
///   // CSS Output
///   .element {
///     position: absolute;
///     top: 10px;
///     bottom: 20px;
///     left: 30px;
///   }

@mixin _absolute(
  $box-edge-values: null null null null
) {
  @if length($box-edge-values) > 4 {
    @error "The number of values must be less than or equal to 4.";
  }

  $count: 1;
  $properties: top, right, bottom, left;

  position: absolute;

  @each $i in $box-edge-values {
    #{nth($properties, $count)}: $i;
    $count: $count + 1;
  }
}

/// `position:fixed` と位置の指定を1行で設定する
///
/// @param {list} $box-edge-values [null null null null]
///   要素の位置。
///   top, right, bottom, left の順で値がセットされる。
///   スキップする場合は `null` 値を使う。
///
/// @example scss
///   .element {
///     @include _fixed(10px null 20px 30px);
///   }
///
///   // CSS Output
///   .element {
///     position: fixed;
///     top: 10px;
///     bottom: 20px;
///     left: 30px;
///   }

@mixin _fixed(
  $box-edge-values: null null null null
) {
  @if length($box-edge-values) > 4 {
    @error "The number of values must be less than or equal to 4.";
  }

  $count: 1;
  $properties: top, right, bottom, left;

  position: fixed;

  @each $i in $box-edge-values {
    #{nth($properties, $count)}: $i;
    $count: $count + 1;
  }
}
