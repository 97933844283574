*,*:before,*:after { box-sizing: border-box }
audio, canvas, iframe, img, svg, video { vertical-align: middle; }
main { display: block; }

//

html {
  font-size: $dots-base-font-size-px;
}
body {
  color: $font-color-primary;
  background: $background-color-primary;
  font-family: $font-stack-yu-gothic-medium;
  font-feature-settings: "palt";
  letter-spacing: .06em;
}
a {
  color: $font-color-primary;
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
}
h1,
h2,
h3 {
  margin: 0;
  font-size: 100%;
  font-weight: normal;
}
p {
  margin: 0;
}
hr {
  //border-top: 1px dotted $border-color-primary;
}
