@charset "UTF-8";

/// 指定された方向を指す「く」の字型の矢印を生成する
///
/// @param {string} $direction
///   矢印の指す方向。`up`, `right`, `down`, `left`,
///   `up-right`, `down-right`, `down-left`, `up-left` をサポートする。
///
/// @param {number (with unit)} $size
///   矢印のサイズ。1辺の長さとしてセットされる。
///
/// @param {number (with unit)} $weight [$dots-arrow-weight]
///   矢印の線の太さ。
///
/// @param {color} $color [$dots-arrow-color]
///   矢印の色。
///
/// @param {string} $display [$dots-arrow-display]
///   矢印の `display` プロパティの値。
///
/// @require {variable} $dots-arrow-weight
/// @require {variable} $dots-arrow-color
/// @require {variable} $dots-arrow-display
///
/// @example scss
///   .element {
///     @include _arrow(up, 50px, 2px);
///   }
///
///   // CSS Output
///   .element {
///     display: block;
///     width: 50px;
///     height: 50px;
///     border-color: #000 #000 transparent transparent;
///     border-style: solid;
///     border-width: 2px 2px 0 0;
///     transform: rotate(-45deg);
///   }

@mixin _arrow(
  $direction,
  $size,
  $weight: $dots-arrow-weight,
  $color: $dots-arrow-color,
  $display: $dots-arrow-display
) {
  $directions-list: 'up' 'right' 'down' 'left' 'up-right' 'down-right' 'down-left' 'up-left';
  @if not index($directions-list, $direction) { @error "$direction must be #{$directions-list}."; }

  display: $display;
  width: $size;
  height: $size;
  border-color: $color $color transparent transparent;
  border-style: solid;
  border-width: $weight $weight 0 0;
  transform-origin: center;

  @if      $direction == 'up'    { transform: rotate(-45deg);  }
  @else if $direction == 'right' { transform: rotate(45deg);   }
  @else if $direction == 'down'  { transform: rotate(135deg);  }
  @else if $direction == 'left'  { transform: rotate(-135deg); }
  @else if $direction == 'up-right'   {}
  @else if $direction == 'down-right' { transform: rotate(90deg);  }
  @else if $direction == 'down-left'  { transform: rotate(180deg); }
  @else if $direction == 'up-left'    { transform: rotate(-90deg); }
}
