.card {
  display: block;
  background: no-repeat center;
  background-size: cover;

  &__media,
  &__content {
    display: block;
  }

  &__media {
    background: no-repeat center;
    background-size: cover;

    > img {
      width: 100%;
      height: auto;
    }
  }

  &--caption {
    position: relative;
    overflow: hidden;

    .card__content {
      z-index: 1;
      @include _absolute(0 null null 0);
      width: 100%;
      height: 100%;
      background: rgba(#000, .6);
      opacity: 0;
    }

    &:hover .card__content {
      opacity: 1;
    }
  }
}

/*
<figure>
  <img src="image.jpg" />
  <figcaption>
    <h3>Caption Title</h3>
    <p>caption text here ...</p>
  </figcaption>
</figure>


figcaption {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,.6);
  -webkit-transition: .3s;
  transition: .3s;
  opacity: 0;
}
figure:hover figcaption {
  opacity: 1;
}
*/
